import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AuthRequiredModal = ({authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin}) => {
    const closeModal = () => {
        setAuthRequiredModalOpen(false);
    };

    const confirmModal = () => {
        setAuthRequiredModalOpen(false);
        triggerLogin();
    };

    return (
        <Modal
            onHide={closeModal}
            show={authRequiredModalOpen}
        >
            <Modal.Header>Auth required</Modal.Header>
            <Modal.Body>
                <p>Do you want to re-authenticate?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>
                    No
                </Button>
                <Button positive onClick={confirmModal}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AuthRequiredModal;
