import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from "react-bootstrap/Stack";
import {SalesOrderHeader} from "./com/nike/SalesOrderHeader";

interface Props {
    submit: (salesOrderHeader: SalesOrderHeader) => void,
    accountNotChosen: () => void
}

interface Item {
    index: number,
    gtin?: string,
    quantity?: number,
}

function FormExample(props: Props) {
    const [validated, setValidated] = useState(false);
    const [poNumber, setPoNumber] = useState('');
    const [crd, setCrd] = useState<Date>();
    const [account, setAccount] = useState(0);
    const [items, setItems] =
        useState<Array<Item>>([{index: 0}]);

    const validateForm = () => {
        return true;
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity() || !validateForm()) {
            event.stopPropagation();
            event.preventDefault();
            setValidated(true);
            return;
        }
        event.preventDefault();

        let salesOrderHeader: SalesOrderHeader = {
            purchaseOrderByCustomer: poNumber === undefined ? "" : poNumber,
            requestedDeliveryDate: crd === undefined ? "" : crd.toString(),
            orderItem: items.map(item => {
                return {
                    requestedQuantity: item.quantity === undefined ? "0" : item.quantity.toString(),
                    salesOrderScheduleLine: [{
                        upc: item.gtin === undefined ? "000" : item.gtin
                    }]
                }
            })
        }

        props.submit(salesOrderHeader)
    }

    const updatePoNumber = (event: React.ChangeEvent<HTMLInputElement>) => setPoNumber(event.currentTarget.value)
    const updateCrd = (event: React.ChangeEvent<HTMLInputElement>) =>
        setCrd(new Date(event.currentTarget.value))
    const getItemIndices = (): number[] => items.map(item => item.index)
    const addItem = () => setItems([...items, {index: items.length}])
    const saveGtin = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setItems(items.map(item => {
                if (item.index !== i)
                    return item;
                return {
                    index: item.index,
                    gtin: event.currentTarget.value,
                    quantity: item.quantity
                }
            })
        )
    }
    const saveQuantity = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setItems(
            items.map(item => {
                if (item.index !== i)
                    return item;
                return {
                    index: item.index,
                    gtin: item.gtin,
                    quantity: parseInt(event.currentTarget.value),
                }
            })
        )
    }

    const deleteButton = (i: number) => {
        if (items.length > 1) {
            return <Button variant="danger" onClick={deleteItem(i)}>Delete</Button>
        }
        return <Button variant="secondary" disabled>Delete</Button>
    }

    const deleteItem = (deletedIndex: number) => () => {
        const newItems = items.flatMap(item => {
                if (item.index === deletedIndex)
                    return []
                return [item]
            }
        )
        let i = 0;
        const newItemsWithCorrectIndexes: Array<Item> = newItems.map(item => {
            return {
                index: i++,
                gtin: item.gtin,
                quantity: item.quantity
            }
        });
        setItems(newItemsWithCorrectIndexes)
    }

    const makeID = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const generatePoNumber = () => {
        if (account === undefined) {
            props.accountNotChosen()
            return
        }
        let accountPrefix = account === 0 ? 'FTL' : (account === 1 ? 'ZA' : 'JD');

        setPoNumber(`QA_${accountPrefix}_` + makeID(16))
    }

    const setAccountFromInput = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setAccount(parseInt(event.currentTarget.value))
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Account</Form.Label>
                    <Stack direction="horizontal" gap={3}>
                        <Form.Select aria-label="Default select example" onChange={setAccountFromInput}>
                            <option value="0">Footlocker</option>
                            <option value="1">Zalando</option>
                            <option value="2">JD (Experimental)</option>
                        </Form.Select>
                    </Stack>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>PO Number</Form.Label>
                    <InputGroup hasValidation className="mb-2">
                        <Form.Control
                            required
                            type="text"
                            placeholder="PO Number"
                            value={poNumber}
                            onChange={updatePoNumber}
                        />
                        <Button variant="outline-primary" id="button-addon2" onClick={generatePoNumber}>
                            Generate
                        </Button>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>CRD</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        onChange={updateCrd}
                        placeholder="CRD"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please choose a CRD.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            aria-describedby="inputGroupPrepend"
                        />
                        <InputGroup.Text id="inputGroupAppend">@nike.com</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Row>

            {getItemIndices().map(i =>
                <Row className="mb-3">
                    <Form.Label>Item #{i + 1}</Form.Label>
                    <Form.Group className="mb-3">
                        <Stack direction="horizontal" gap={3}>
                            <Form.Control type="text"
                                          id={"gtin" + i}
                                          placeholder="GTIN"
                                          required
                                          onChange={saveGtin(i)}
                            />
                            <Form.Control type="number"
                                          id={"quantity" + i}
                                          placeholder="Quantity"
                                          required
                                          onChange={saveQuantity(i)}
                            />
                            {deleteButton(i)}
                        </Stack>
                    </Form.Group>
                </Row>
            )}
            <Form.Group className="mb-3">
                <Button variant="primary" onClick={addItem}>
                    Add 1 more line
                </Button>
            </Form.Group>

            <Form.Group className="mb-3">
                <Button type="submit">Submit order</Button>
            </Form.Group>
        </Form>
    );
}

export default FormExample;
