import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";

interface ModalWindowProps {
    show: boolean,
    onHide: () => void,
    itemsCount: number,
    showLoader: boolean,
    errorMessage?: string,
    orderId?: string
}

export class ModalWindow extends React.Component<ModalWindowProps, any> {
    loader() {
        if (this.props.showLoader) {
            return <div className="spinner-border text-success" role="status">
                <span className="sr-only"></span>
            </div>
        }
        return <></>
    }

    errorMessage() {
        if (this.props.errorMessage === undefined) {
            return <></>
        }
        return <Alert key="danger" variant="danger">
            Error occurred: {this.props.errorMessage}
        </Alert>
    }

    infoMessage() {
        if (this.props.orderId === undefined) {
            return <></>
        }
        return <Alert key="success" variant="success">
            Order Uploaded: {this.props.orderId}
        </Alert>
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Sending the order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.errorMessage()}
                    {this.infoMessage()}
                    {`Sending ${this.props.itemsCount} items`}...
                    {this.loader()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
