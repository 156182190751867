import React, {Component} from 'react';
import './App.css';
import {LoginCallback, SecureRoute, Security, withOktaAuth} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import Header from "./Navbar";
import Container from 'react-bootstrap/Container';
import {SalesOrderHeader} from "./com/nike/SalesOrderHeader";
import OrderCreateClient from "./com/nike/OrderCreateClient";
import {ModalWindow} from "./ModalWindow";
import {Alert} from "react-bootstrap";
import FormExample from "./Form";
import AuthRequiredModal from "./AuthRequiredModal";
import Home from "./Home";

const oktaAuth = new OktaAuth({
    issuer: 'https://nike-qa.oktapreview.com/oauth2/default',
    clientId: 'nike.niketech.ordercreate-emea-qa-ui',
    redirectUri: window.location.origin + '/redirect',
    scopes: ['ordermgmt.zalandoadapter.create', 'ordermgmt.zalandoadapter.read'],
});

const App = (): React.JSX.Element => {
    const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
    const history = useHistory(); // example from react-router

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    const triggerLogin = async () => {
        await oktaAuth.signInWithRedirect();
    };

    const customAuthHandler = async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState || !previousAuthState.isAuthenticated) {
            // App initialization stage
            await triggerLogin();
        } else {
            // Ask the user to trigger the login process during token autoRenew process
            setAuthRequiredModalOpen(true);
        }
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Header oktaAuth={oktaAuth}/>
            <AuthRequiredModal {...{authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin}} />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path='/redirect' component={LoginCallback}/>
                <SecureRoute path='/order/submit' component={OrderSubmitPage}/>
            </Switch>
        </Security>
    )
}

interface HomePageState {
    showModal: boolean,
    orderBody?: SalesOrderHeader,
    orderId?: string,
    errorMessage?: string,
    showLoader: boolean,
    accountNotChosen: boolean
}

const OrderSubmitPage =
    withOktaAuth(class Home extends Component<IOktaContext, HomePageState> {
        private orderCreateClient;

        constructor(props: IOktaContext) {
            super(props);
            this.login = this.login.bind(this);
            this.state = {
                showModal: false,
                showLoader: false,
                accountNotChosen: false,
            }
            this.orderCreateClient = new OrderCreateClient(this.props.oktaAuth);
        }

        async login() {
            await this.props.oktaAuth.signInWithRedirect();
        }

        handleClose = () => {
            this.setState({showModal: false})
        }

        submit = (salesOrderHeader: SalesOrderHeader) => {
            this.setState({
                orderBody: salesOrderHeader,
                showLoader: true,
                showModal: true
            })
            const orderId =
                this.orderCreateClient.sendOrder(salesOrderHeader)
            orderId
                .then(o => this.setState({orderId: o, showLoader: false}))
                .catch(e => this.setState({errorMessage: e.message, showLoader: false}))
        }

        accountNotChosen = () => {
            this.setState({accountNotChosen: true});
            setTimeout(() => {
                this.setState({accountNotChosen: false})
            }, 4000)
        }

        renderAccountNotChosenAlert = () => {
            if (this.state.accountNotChosen) {
                return <Alert key={"danger"} variant={"danger"}>
                    Account Not Chosen!
                </Alert>
            }
            return <></>
        }

        render() {
            return (
                <>
                    <ModalWindow show={this.state.showModal}
                                 onHide={this.handleClose}
                                 itemsCount={this.state.orderBody ? this.state.orderBody.orderItem.length : 0}
                                 showLoader={this.state.showLoader}
                                 errorMessage={this.state.errorMessage}
                                 orderId={this.state.orderId}/>
                    <Container className="p-3">
                        {this.renderAccountNotChosenAlert()}
                        <h1>Create Order</h1>
                        <FormExample submit={this.submit} accountNotChosen={this.accountNotChosen}/>
                    </Container>
                </>
            )
        }
    });

const AppWithRouterAccess = () => (
    <Router>
        <App/>
    </Router>
);

export default AppWithRouterAccess;
