import {SalesOrderHeader} from "./SalesOrderHeader";
import axios from "axios";
import {OktaAuth} from "@okta/okta-auth-js";

class OrderCreateClient {
    private oktaAuth;
    private orderCreateUri =
        "https://zalandoadapter-test.mp-ordermgmt-test.nikecloud.com/order_mgmt/zalando/v2/"

    constructor(oktaAuth: OktaAuth) {
        this.oktaAuth = oktaAuth;
    }

    private extractOrderIdFromResponseBody(data: any): string {
        const body = data as string;
        return JSON.parse(body).id
    }

    public async sendOrder(salesOrderHeader: SalesOrderHeader): Promise<string> {
        const payload = JSON.stringify({
            request: {salesOrderHeader: salesOrderHeader}
        })
        let token = this.oktaAuth.getAccessToken();
        if (token === undefined) {
            console.log(`no token in ${this.oktaAuth}`)
            throw new Error("please authorise");
        }

        let response =
            await axios.post(this.orderCreateUri, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            })
        if (response.status < 300) {
            return this.extractOrderIdFromResponseBody(response.data);
        }
        throw new Error(`bad response [${response.status}]: ` + response.data);
    }
}

export default OrderCreateClient;
